.modalOverlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;

  &__admin {
    background-color: #fafafa;
  }
}

.closeBtn {
  position: absolute;
  right: 0;
  top: 0;
  padding: 8px;
  cursor: pointer;
  transition: color 0.5s ease;

  &:hover {
    color: #3ea1f9;
  }
}

.modal-authorization {
  z-index: 100;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 12px;
  display: flex;
  flex-wrap: wrap;
  max-width: 555px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  &--admin {
    max-width: 540px;

    .modal-authorization-side--left input {
      max-width: 272px;
      width: 100%;
      margin-left: 0.5rem;
    }
  }
}

.button {
  &Container {
    width: 100%;
    padding: 0 30px 30px;
  }
}

@media only screen and (max-width: 768px) {
  .modalOverlay {
    display: none;
  }

  .modal-authorization {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: none;
    border-radius: 0;
    max-width: none;
    height: 100%;
    flex-direction: column;
  }

  // .closeBtn {
  //   display: none;
  // }
}
