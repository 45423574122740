.select {
  width: 0.1px;
  height: 0.1px;
  position: absolute;
  z-index: -1;
  overflow: hidden;
  opacity: 0;
}

.rootElement {
  position: relative;
  cursor: pointer;
}

.selectorWrapper {
  background-color: transparent;
  width: 100%;
  height: 100%;
  max-width: 160px !important;
  font-size: 15px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  border: solid 2px #b6b6b6;
  box-sizing: border-box;
  border-left: none;
  color: #8c8c8c;
  &:focus {
    outline: none;
  }

  svg {
    -webkit-transition-duration: 0.2s;
    -moz-transition-duration: 0.2s;
    -o-transition-duration: 0.2s;
    transition-duration: 0.2s;

    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    transition-property: transform;
    transform: translate(-50%, -50%) rotate(90deg);
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 300;
}

.list {
  max-height: 500px;
  overflow-y: auto;
  position: absolute;
  top: -8px;
  left: 0;
  width: 260px;
  min-height: 30px;
  padding-top: 8px;
  border-radius: 8px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  border: solid 1px rgba(0, 0, 0, 0.6);
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  z-index: 300;

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
    background-color: #cccccc;
  }

  &::-webkit-scrollbar {
    width: 10px;
    background-color: gray;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }

  ul {
    list-style: none;
    padding: 0;
    width: 100%;

    li {
      height: 28px;
      font-family: Arial;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      display: flex;
      align-items: center;
      text-align: start;
      padding-left: 15px;
      cursor: pointer;

      &:hover {
        background-color: #3ea1f1;
      }

      &.selected {
        background-color: #3ea1f9;
      }
    }
  }
}
