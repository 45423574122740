.inputLabel {
  display: flex;
  align-items: center;
}

.name {
  padding-right: 12px;
  font-size: 16px;
  font-weight: normal;
  text-align: left;
  color: #4a4a4a;
  max-width: 50%;
}

.input {
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 7px 15px;
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
  text-align: left;
  color: #000;
  flex: 1 1 auto;
  transition: border-color 0.3s ease;
  max-width: 100%;
  width: 100%;

  &Wrapper {
    position: relative;
    width: 100%;
  }

  &:focus {
    border-color: #3ea1f9;
  }
}

.label {
  display: block;
  cursor: pointer;

  &:hover {
    .labelName {
      color: #3ea1f9;
    }
  }

  input {
    width: 0.1px;
    height: 0.1px;
    position: absolute;
    z-index: -1;
    overflow: hidden;
    opacity: 0;
  }

  &Name {
    transition: color 0.3s ease;
    word-break: break-all;
    font-size: 14px;
    color: #000;
  }
}

.checkbox {
  input {
    &:checked + .checkboxInput {
      i {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        visibility: visible;
        color: #3ea1f9;
      }
    }
  }

  &Input {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 6px;
    border-radius: 2px;
    border: 1px solid #4a4a4a;
    cursor: pointer;
    transform: translate3d(0, 3px, 0);

    i {
      position: absolute;
      left: 0;
      top: 0;
      font-size: 11px;
      transition: transform 0.3s ease, opacity 0.3s ease, visibility 0.3s ease,
        color 0.3s ease;
      transform: translate3d(0, -13px, 0);
      opacity: 0;
      visibility: hidden;
      color: #000;
    }
  }
}

.radio {
  input {
    &:checked + .radioInput {
      div {
        opacity: 1;
        color: #3ea1f9;
      }
    }
  }

  &Input {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 6px;
    border-radius: 50%;
    border: 2px solid #3ea1f9;
    cursor: pointer;
    transform: translate3d(0, 3px, 0);

    div {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 6px;
      height: 6px;
      transition: opacity 0.3s ease;
      opacity: 0;
      background-color: #3ea1f9;
      border-radius: 50%;
    }
  }
}

.password {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;

  i {
    transition: color 0.3s ease;
  }

  &:hover {
    color: #3ea1f9;
  }
}

@media only screen and (max-width: 510px) {
  .inputLabel {
    flex-wrap: wrap;
  }

  .label {
    display: block;
  }
  .name {
    max-width: 100%;
    width: 100% !important;
    padding: 0;
    margin-bottom: 6px;
    font-size: 12px;
  }
  .input {
    width: 100%;
  }
}
