@import "assets/styles/font-faces/font-faces";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: "IBM_Plex_Sans";
        font-weight: 400;
        src: url("../public/fonts/IBMPlexSans-Regular.ttf") format("truetype");
    }

    @font-face {
        font-family: "IBM_Plex_Sans";
        font-weight: 500;
        src: url("../public/fonts/IBMPlexSans-Medium.ttf") format("truetype");
    }

    html {
        font-family:
            "IBM_Plex_Sans",
            -apple-system,
            system-ui,
            sans-serif;
        @apply bg-c-1 text-c-17;
    }
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
