.btn {
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #3ea1f9;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  border: 1px solid #3ea1f9;
  transition: border-color 0.3s ease, color 0.3s ease,
    background-color 0.3s ease;

  &__gray {
    background-color: #c7c7c7;
    border-color: #c7c7c7;
  }

  &__red {
    background-color: red;
    border-color: red;
  }

  &:hover {
    background-color: #fff;
    color: #3ea1f9;
  }
}
