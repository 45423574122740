.block {
  padding: 30px;
  font-size: 16px;
  color: #000;
}

@media only screen and (max-width: 575px) {
  .block {
    font-size: 13px;
    padding: 15px;
  }
}