.block {
  padding: 40px 0;
}

.social {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  color: white;

  &s {
    &Inner {
      display: flex;
      flex-wrap: wrap;
      margin: -8px;

      &Item {
        padding: 8px;
        flex: none;
      }
    }
  }

  &__vkontakte {
    background-color: #4c75a3;
  }

  &__odnoklassniki {
    background-color: #ed812b;
  }

  &__facebook {
    background-color: #4267b2;
  }

  &__youtube {
    background-color: #ff0000;
  }

  &__twitter {
    background-color: #1da1f2;
  }

  &__instagram {
    background-color: #e1306c;
  }

  &__telegram {
    background-color: #0088cc;
  }
}

.copyright {
  font-size: 14px;
  color: #666;
  font-weight: 500;
  margin-top: 10px;

  a {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
