.modal {
  padding: 30px;
  width: 100%;
}

.item {
  &:not(:first-of-type) {
    padding-top: 20px;
  }
}

.error {
  font-size: 14px;
  color: red;
}

.message {
  color: green;
  font-size: 14px;
}

.btn {
  position: relative;
  overflow: hidden;

  &Preloader {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
    display: block;
    padding: 0.5rem;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
  }

  &:disabled {
    cursor: not-allowed;
  }
}