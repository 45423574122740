.error {
  color: red;
}

.createAdModal {
  max-width: 400px;
  width: 100%;
}

.createAd {
  max-width: 1110px;
  margin: 30px auto;
  padding: 30px 30px 20px 30px;
  border-radius: 5px;
  background-color: #ffffff;

  & label {
    font-family: Arial;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4a4a4a;
  }

  &InputWrapper {
    width: 60%;
  }

  &Input,
  & textarea {
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    padding: 8px 15px;
    font-family: Arial;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    width: 100%;
  }

  & textarea {
    resize: none;
    height: 112px;
  }
}

.list {
  display: flex;
  flex-direction: column;
  width: 900px;
  & .withDimension {
    width: 125px;
    position: relative;
    padding-right: 28px;
    & input {
      width: 100%;
    }
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translate(0, -50%);
      width: 20px;
      height: 20px;
      pointer-events: none;
    }
    &.price {
      position: relative;
      padding: 0;

      input {
        padding: 8px 34px 8px 15px;
      }

      &::after {
        content: "сум";
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
      }
    }
    &.quantity::after {
      content: "шт.";
    }
  }
}

.list .item {
  display: flex;
  width: 100%;
  margin-bottom: 28px;

  & label {
    width: 40%;
  }
}

.categoryInputWrapper {
  flex: 1 1 auto;
}

.uploadWrapper {
  display: flex;
  width: 60%;
  flex-wrap: wrap;
  & .uploadBtn {
    width: 106px;
    height: 86px;
    padding: 19px 29px;
    border-radius: 4px;
    border: solid 1px #dbdbdb;
    background-color: transparent;
    background-image: url("../../../assets/images/icons/icon-camera.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50%;
    cursor: pointer;
  }

  & .imageItem {
    position: relative;
    & .buttonRotate,
    & .buttonDelete {
      position: absolute;
      top: 3px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      width: 20px;
      height: 20px;
      &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #4a4a4a;
        opacity: 0.1;
        border-radius: 25px;
      }
    }
    & .buttonRotate {
      left: 3px;
      background-image: url("../../../assets/images/icons/icon-refresh.svg");
    }
    & .buttonDelete {
      right: 3px;
      background-image: url("../../../assets/images/icons/icon-close.svg");
    }
  }
}

.form {
  display: flex;
}
.submitBtnWrapper {
  display: flex;
  flex-direction: column;
}
.submitBtn {
  cursor: pointer;
  max-width: 260px;
  margin: 40px 0;
  padding: 14px 33px 13px 33px;
  border-radius: 4px;
  background-color: #3ea1f9;
  font-family: Arial;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  position: relative;

  &Preloader {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition:
      opacity 0.3s ease,
      visibility 0.3s ease;
    opacity: 0;
    visibility: hidden;

    &Element {
      padding: 4px !important;
    }
  }

  &:disabled {
    cursor: not-allowed;

    .submitBtnPreloader {
      opacity: 1;
      visibility: visible;
    }
  }
}

.description {
  white-space: pre-wrap;

  &Characters {
    font-size: 12px;
  }
}

@media screen and (max-width: 900px) {
  .createAd {
    max-width: 600px;
    &Input,
    & textarea {
      width: 100%;
    }
    .submitBtn {
      max-width: none;
      width: 100%;
    }
  }
  .list {
    width: 100%;
    & .withDimension {
      width: 125px;
    }
  }
  .list .item {
    flex-direction: column;
    & label {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  .uploadWrapper {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .createAdModal {
    max-width: none;
  }
}
