.input {
  &Wrapper {
    position: relative;
  }

  &Prefix {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    pointer-events: none;

    &__right {
      left: auto;
      right: 0;
    }

    &__button {
      cursor: pointer;
      pointer-events: auto;

      i {
        transition: color 0.3s ease;
      }

      &:hover {
        i {
          color: #3ea1f9;
        }
      }
    }
  }
}
