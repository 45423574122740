$transitionSpeed: 0.5s;
$transitionFunc: linear;

.content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  background-color: #fff;

  &__full {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000;
  }

  &Enter {
    opacity: 0;

    &Active {
      transition: opacity $transitionSpeed $transitionFunc;
      opacity: 1;
    }

    &Done {
      opacity: 1;
    }
  }

  &Exit {
    opacity: 1;

    &Active {
      transition: opacity $transitionSpeed $transitionFunc;
      opacity: 0;
    }

    &Done {
      opacity: 0;
    }
  }
}

.loader {
  width: 50px;
  height: 50px;
  max-width: 100%;
  max-height: 100%;

  img,
  svg {
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }
}
