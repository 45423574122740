.container {
  max-width: 1140px;
  width: 100%;
  padding: 15px 15px;
  margin: 0 auto;

  &Admin {
    max-width: 1600px;
  }
}

@media only screen and (max-width: 1600px) {
  .container {
    &Admin {
      max-width: 1400px;
    }
  }
}

@media only screen and (max-width: 1400px) {
  .container {
    &Admin {
      max-width: 1110px;
    }
  }
}

@media only screen and (max-width: 1110px) {
  .container {
    padding: 0 15px;

    &Admin {
      padding: 0 10px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .container {
    margin-top: 85px;
  }
}

@media only screen and (max-width: 575px) {
  .container {
    margin-top: 76px;
  }
}
