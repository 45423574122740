.rootElement {
  position: relative;

  & .dropdown {
    position: absolute;
    bottom: -5px;
    padding: 10px 0;
    right: 0;
    border-radius: 4px;
    box-shadow: 0 8px 16px 0 rgba(8, 35, 48, 0.2);
    border: solid 1px #dbdbdb;
    background-color: #ffffff;
    transform: translate(0%, 100%);
    z-index: 100;
    width: 95%;
    min-width: 250px;
    max-height: 500px;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 12px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: lightgray;
    }

    & li {
      padding: 10px 15px;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
      &:hover {
        background-color: #f7f7f7;
      }
    }
  }
}

.input {
  width: 100%;
  padding: 6px 15px;
  z-index: 2;
}

.container {
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 6px 15px;
  width: 250px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0%, -50%);
    background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbdZ2s5uh630ZoXToUIwTU6c2ipKypAWlnyA&usqp=CAU");
    background-position: center;
    background-size: cover;
    width: 8px;
    height: 10px;
  }

  &__reset {
    padding: 0;
  }
}

.label {
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.seperator {
  border-bottom: 1px solid #777;
  font-size: 14px;
  padding: 4px 16px;
  cursor: default;

  &:hover {
    background-color: #fff !important;
  }
}

.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1;
}

.select {
  width: 0.1px;
  height: 0.1px;
  position: absolute;
  z-index: -1;
  opacity: 0;
  overflow: hidden;

  & option {
    border: none;
    outline: none;
  }
}

@media screen and (max-width: 900px) {
  .container {
    width: 100%;
  }
}
