.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 9999;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 100%;
  text-align: center;
}

.closeButtonWrapper {
  position: absolute;
  top: 10px;
  right: 10px;
}

.closeButton {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.error {
  font-size: 16px;
  margin-bottom: 20px;
}
