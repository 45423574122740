.block {
  margin-top: 20px;
}

.paragraph {
  font-size: 14px;
  color: #000;
}

.timer {
  color: #8c8c8c;
}

.title {
  font-size: 32px;
  font-weight: 700;
  color: #000;
}

.container {
  padding: 30px;
  width: 100%;
}

.button {
  width: 100%;
  padding: 8px 15px;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  &Approve {
    background-color: #3ea1f9;
    border-color: #3ea1f9;
    color: #fff;

    &:hover {
      background-color: #fff;
      color: #3ea1f9;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;

      &:hover {
        background-color: #3ea1f9;
        color: #fff;
      }
    }
  }

  &Back {
    border-color: #4a4a4a;
    background-color: #fff;
    color: #4a4a4a;

    &:hover {
      background-color: #4a4a4a;
      color: #fff;
    }
  }

  &Get {
    color: #3ea1f9;
    cursor: pointer;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    opacity: 0;
    visibility: hidden;

    &__active {
      opacity: 1;
      visibility: visible;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &s {
    &Inner {
      margin: -15px;
      display: flex;
      flex-wrap: wrap;
    }

    &Item {
      width: 50%;
      padding: 15px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .title {
    font-size: 26px;
  }
}

@media only screen and (max-width: 539px) {
  .container {
    padding: 15px;
  }

  .title {
    font-size: 22px;
  }

  .buttons {
    &Inner {
      margin: -8px;
    }

    &Item {
      width: 100%;
      padding: 8px;
    }
  }
}
