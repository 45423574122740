.content {
  padding: 30px;

  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}

.close {
  display: block;
  margin: 12px auto 0;
  width: 100%;

  &:hover {
    color: red;
  }
}