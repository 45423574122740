.btn {
  &Title {
    display: inline !important;
    background-color: transparent !important;
    color: #3ea1f9 !important;
    border: none;
    outline: none;
    font-family: Arial;
    font-size: 20px !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left !important;
    margin-top: 0 !important;
    padding: 0 !important;
    height: auto !important;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &HasAccount {
    background-color: #fff !important;
    color: #3ea1f9 !important;
    cursor: pointer;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &Reset {
    margin: 0 !important;
    padding: 0 !important;
    background-color: transparent !important;
    color: #3ea1f9 !important;
    cursor: pointer !important;

    &:hover {
      text-decoration: underline;
    }
  }
}

.submitBtn {
  cursor: pointer;
  position: relative;

  &Preloader {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    opacity: 0;
    visibility: hidden;

    &Element {
      padding: 4px !important;
    }
  }

  &__submitting {
    cursor: not-allowed;

    .submitBtnPreloader {
      opacity: 1;
      visibility: visible;
    }
  }

  &:disabled {
    opacity: 0.5;
    background-color: #ccc;
    cursor: not-allowed;
  }
}

.subtitle {
  font-size: 14px;
  font-weight: 400;
  color: #000;

  span {
    color: #3ea1f9;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.agreementBlock {
  display: flex;
}

.user-agreement a {
  color: #3ea1f9;
}

.passwords_error {
  font-size: 14px;
  color: red;
}

.checkboxWrapper {
  padding: 0 11px;
  padding-bottom: 10px;
}

.input {
  padding: 8px 14px 8px 15px;
  border-radius: 4px;
  border: solid 1px #dbdbdb;
  background-color: #ffffff !important;
  flex: 0 1 60%;
  margin-left: 15px;

  &Wrapper {
    flex: 0 1 60%;
    margin-left: 15px;
  }
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.modal-authorization-side {
  &--left {
    flex: 1 0 auto;
    padding: 30px;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 100%;

    p {
      font-family: Arial;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
    }

    &-title {
      font-family: Arial;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      margin-bottom: 32px;
    }

    button {
      height: 32px;
      padding: 6px 2px 8px 15px;
      border-radius: 4px;
      background-color: #3ea1f9;
      color: #fff;
      border: none;
      outline: none;
      font-family: Arial;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      margin-top: 20px;
    }

    form {
      display: flex;
      flex-direction: column;
    }

    label {
      font-family: Arial;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a;
    }
  }

  &--right {
    display: none;
    padding: 30px;
    flex: 1;
    border-left: 1px solid #d8d8d8;

    h2 {
      font-family: Arial;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      margin-bottom: 40px;
    }

    &-buttons {
      display: flex;
      flex-direction: column;
      padding: 0;
      & li {
        margin-bottom: 20px;
        & button {
          width: 100%;
          padding: 8px 15px;
          border-radius: 4px;
          border: solid 1px #4a4a4a;
          background-color: #ffffff;
          font-family: Arial;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #000000;
          cursor: pointer;
        }
      }
    }

    &-mobile {
      &-top {
        display: none;

        position: relative;
        padding: 12px 16px;
        justify-content: center;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);

        &-btn {
          padding: 8px 15px;
          border-radius: 16px;
          background-color: #fff;
          font-size: 14px;
          font-weight: 400;
          color: #3ea1f9;

          &--back {
            padding: 0;
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translate3d(0, -50%, 0);
            width: 35px;
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #3ea1f9;
            font-size: 20px;
          }

          &--active {
            background-color: #3ea1f9;
            color: #fff;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .radios {
    display: none;
  }

  .subtitle {
    display: none;
  }

  .modal-authorization-side {
    &--left {
      order: 1;
      padding: 15px;
      background-color: #fafafa;

      &-title {
        display: none;
      }

      form {
        div {
          flex-wrap: wrap;
        }
      }

      label {
        width: 100%;
        margin-bottom: 4px;
      }

      input {
        width: 100%;
      }
    }

    &--right {
      display: block;
      padding: 0;
      flex: none;

      &-title {
        display: none;
      }

      &-buttons {
        display: none;
      }

      &-mobile {
        &-top {
          display: flex;
        }
      }
    }
  }
}
