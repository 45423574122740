* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html {
  width: 100%;
  height: 100%;
}
body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: larger;
  background-color: #fafafa;
  overflow-x: hidden;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

button {
  background-color: transparent;
  border: none;
  outline: none;
}
h2 {
  padding: 0;
  margin: 0;
}

p {
  margin: 0;
}

li {
  margin: 0;
  padding: 0;
}

input {
  outline: none;
  border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  background-color: #b3e6ff;
}

.title {
  text-align: center;
}

.clearfix {
  clear: both;
}

.pointer {
  cursor: pointer;
}

.top-container {
  width: 100%;
  box-shadow: 0 0 4px #d8d8d8;
}

@media only screen and (max-width: 1110px) {
  .top-container {
    box-shadow: none;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .top-container {
    position: fixed;
    left: 0;
    top: 0;
    margin: 0;
    z-index: 100;
  }
}

.btn {
  padding: 9px 15px;
  border-radius: 3px;
  text-decoration: none !important;
  font-size: 14px;
  border: 0;
}
.btn.btn-default {
  color: #000;
  background: #d8d8d8;
}
.btn-primary {
  width: 161px;
  height: 32px;
  margin: 0 0 0 24px;
  padding: 8px 16px 8px 16px;
  border-radius: 4px;
  background-color: #3ea1f9;
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.page-title {
  font-size: 18px;
}
