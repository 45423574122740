.wrapper {
  position: relative;

  &__error {
    input,
    textarea {
      transition: border-color 0.3s ease;
      border-color: red !important;
    }
  }
}

.errorMessage {
  position: absolute;
  top: 100%;
  left: 0;
  color: red;
  font-size: 16px;
  white-space: nowrap;
}
